@import 'simple-notify/dist/simple-notify.min.css';
// TODO заменить на китовые тостеры

.notifications-container {
  max-width: 40rem;
}

.notify.notify--type-1 {
  padding: 2.2rem var(--mg-space-24) var(--mg-space-24) var(--mg-space-48);
  border: .1rem solid #ffffff;
  border-radius: var(--mg-radius-4);
  background: #ffffff var(--mg-space-24) var(--mg-space-24) / var(--mg-space-16) no-repeat;
  --notify-close-icon-size: 1.4rem;

  .notify__title {
    font: var(--mg-font-m);
    color: var(--mg-color-primary-50);
  }

  .notify__close {
    top: 0;
    right: 0;
    width: calc(var(--notify-close-icon-size) + var(--mg-space-16));
    height: calc(var(--notify-close-icon-size) + var(--mg-space-16));
    padding: var(--mg-space-8);
  }

  .notify__text {
    font: var(--mg-font-xs);
    white-space: pre-line;
    color: var(--mg-color-primary-50);

    a {
      color: var(--mg-color-accent-30);
    }
  }

  .notify__title + .notify__text {
    margin-top: var(--mg-space-8);
  }

  &.notify-autoclose::before {
    display: none;
  }

  &.notify--success {
    border-color: var(--mg-color-green-40);
    background-color: var(--mg-color-green-10);
    background-image: url('/assets/images/icons/success.svg');
  }

  &.notify--error {
    border-color: var(--mg-color-red-40);
    background-color: var(--mg-color-red-10);
    background-image: url('/assets/images/icons/error.svg');
  }
}
