.mg-modal--nopadding .cdk-dialog-container {
  padding: 0;
}

.authorized-button-modal {
  width: 120rem;

  @media (max-width: 1200px) {
    width: calc(100vw - var(--mg-space-24));
  }

  @media (max-width: 768px) {
    .cdk-dialog-container {
      padding: var(--mg-space-12);
    }
  }
}
