@import '../node_modules/@marketguru/ui-kit-components/assets/styles/scss/main';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

html {
  height: 100%;
}

body {
  height: 100%;
  background: var(--mg-color-light) !important;
}

a:hover {
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 172rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0 var(--mg-space-60);

  @media (max-width: 1280px) {
    padding: 0 var(--mg-space-52);
  }

  @media (max-width: 992px) {
    padding: 0 var(--mg-space-20);
  }

  @media (max-width: 767px) {
    padding: 0 var(--mg-space-16);
  }

  &--wide {
    max-width: 100%;
    padding: 0 var(--mg-space-20);
  }
}

.content-title {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1.4;

  b {
    font-weight: 800;
  }

  @media (max-width: 1199.98px) {
    font-size: var(--mg-fs-xl);

    br {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    font-size: var(--mg-fs-m);
  }
}

.landing-title {
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.4;
  text-align: center;
  color: var(--mg-color-primary-50);

  @media (max-width: 767.98px) {
    font-size: var(--mg-fs-xl);
  }
}

owl-carousel-o.owl-right-padding {
  .owl-stage {
    padding-left: 0 !important;
  }
}

@import './modules/buttons';
@import './modules/toastr';
@import './modules/video-modal';
@import './pages/advantages-section';

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .col-md-6 {
    width: 50%;
  }
}

@media (min-width: 575px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-flex {
    display: flex !important;
  }
}

.new-year {
  position: relative;

  .snowflake {
    position: absolute;
  }
}
