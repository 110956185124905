.mg-button {
  white-space: nowrap;

  &.cta-button {
    font: var(--mg-font-m);
    display: inline-flex;
    margin-top: var(--mg-space-32);
    padding: var(--mg-space-12) var(--mg-space-16);

    @media (max-width: 991px) {
      margin-top: var(--mg-space-20);
    }

    @media (max-width: 768px) {
      font: var(--mg-font-s);
      min-height: 4.2rem;
      padding: var(--mg-space-8) var(--mg-space-12);
    }
  }

  &:hover {
    color: var(--mg-color-light);
  }
}
